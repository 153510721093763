import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Navigation = ({ series }) => (
  <nav role="navigation" aria-label="main-navigation" className={"my-12"}>
    <ul>
      <li className={"my-2 py-2 tablet:p-0"}>
        <Link
          activeClassName="font-bold underline"
          className={"text-gray text-sm block hover:underline"}
          to="/"
        >
          Home
        </Link>
      </li>
      <li className={"my-2 py-2 tablet:p-0"}>
        <Link
          activeClassName="font-bold underline"
          className={"text-gray text-sm block hover:underline"}
          to="/series"
        >
          Series
        </Link>
        {series && (
          <span className={"text-gray font-bold underline text-sm block"}>
            &#8594; {series}
          </span>
        )}
      </li>
      <li className={"my-2 py-2 tablet:p-0"}>
        <Link
          activeClassName="font-bold underline"
          className={"text-gray text-sm block hover:underline"}
          to="/about"
        >
          About
        </Link>
      </li>
    </ul>
  </nav>
)

Navigation.propTypes = {
  series: PropTypes.string,
}

export default Navigation
