import React, { useState } from "react"
import TitleSite from "./TitleSite"
import Hamburger from "./Hamburger"

const Sidebar = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <aside
      className={`
        z-10
        bg-white
        overflow-auto
        w-auto
        p-5
        tablet:p-8
        tablet:pt-20
        tablet:w-1/6
        tablet:h-screen
        transition-all
        duration-500
        ease-in-out
        ${isOpen ? "h-68" : "h-32"}`}
    >
      <TitleSite />
      <Hamburger onClick={() => setOpen(!isOpen)} isActive={isOpen} />
      {children}
    </aside>
  )
}

export default Sidebar
