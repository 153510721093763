import React from "react"
import { Link } from "gatsby"

const TitleSite = () => (
  <h1
    className={"text-4xl tablet:text-2xl leading-8 tablet:leading-7 font-thin"}
  >
    <Link to="/" className={"hover:no-underline"}>
      the specious
      <br />
      present
    </Link>
  </h1>
)

export default TitleSite
