import React from "react"
import useSiteMetadata from "./SiteMetadata"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import Sidebar from "./sidebar/Sidebar"
import Navigation from "./sidebar/Navigation"
import PropTypes from "prop-types"

const Layout = ({ children, series, subTitle, image }) => {
  const { title, description } = useSiteMetadata()
  const ogImage = image ? image : "/img/og-image.jpg"
  return (
    <div className={"text-gray tablet:flex"}>
      <Helmet>
        <html lang="en" />
        <title>{subTitle ? `${title} - ${subTitle}` : title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#fff"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={subTitle ? `${title} - ${subTitle}` : title}
        />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <Sidebar>
        <Navigation series={series} />
      </Sidebar>
      <main className={"w-full tablet:w-5/6"}>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  series: PropTypes.string,
}

export default Layout
