import React from "react"
import PropTypes from "prop-types"

const Hamburger = ({ onClick, isActive }) => (
  <button
    type="button"
    className={"absolute top-0 right-0 p-5 font-bold tablet:hidden"}
    onClick={onClick}
  >
    {isActive ? "close" : "menu"}
  </button>
)

Hamburger.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

export default Hamburger
